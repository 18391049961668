const domReady = () => {

    return new Promise(resolve => {

        document.addEventListener('DOMContentLoaded', (event) => {
        
            document.body.classList.add('is-ready')
            resolve()
            
        })

    })

}    

export { 
    domReady
}
