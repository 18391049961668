import { domReady } from "./utils/domready"
import MMenu from './components/mmenu-light'

class App {

    static start() {
        return new App()
    }

    constructor() {
        Promise
            .all([
                domReady()
            ])
            .then(
                this.init.bind(this)
            )
    }


    init() {
        console.info('🚀App:init')

         // MM menu
         MMenu()

    }

}

App.start()